import { Link } from '@inertiajs/react';
import { useAppContext } from "../../contexts/app/AppContext";
import Image from "..//Image";
import IconButton from "..//buttons/IconButton";
//import { Link } from '@inertiajs/inertia-react'
import { router, usePage } from '@inertiajs/react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useEffect, useState } from "react";
import useWindowHeight from "../../hooks/useWindowHeight";
import useWindowSize from "../../hooks/useWindowSize";
import AxiosService from "../../services/axios.service";
import Box from "../Box";
import Container from "../Container";
import Divider from "../Divider";
import FlexBox from "../FlexBox";
import { H3, Span, Tiny } from "../Typography";
import Icon from "../icon/Icon";
import CustomerDashboardNavigation from "../layout/SidenavCustomerDashboardNavigation";
import MiniCart from "../mini-cart/MiniCart";
import Login from "../sessions/Logins";
import Sidenav from "../sidenav/Sidenav";
import StyledHeader from "./HeaderStyle";
import Search from "./Search";
import SideMenu from "./SideMenu";
import Bg from './background.svg';
import useLockedScroll from '../../hooks/useLockedScroll';
import { useLockedBody } from 'usehooks-ts';

// Translate
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as MUILink } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { lngs } from '../../i18n';

//@ MUI DRAWER
import Drawer from '@mui/material/Drawer';
import { Button, Menu, Typography } from '@mui/material';
import { deviceSize } from '../../utils/constants';

type HeaderProps = {
  isFixed?: boolean;
  className?: string;
}

const Header: React.FC<HeaderProps> = ({ isFixed, className }) => {
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [openLoginMenu, setOpenLoginMenu] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)
  const [locked, setLocked] = useLockedScroll(false, 'root')
  const { t, i18n } = useTranslation()

  const toggleSidenav = () => {
    setLocked(!locked)
    setOpen(!open)
  }
  const toggleSidenav2 = () => {
    setLocked(!locked)
    setOpen2(!open2)
  }
  const toggleSidenav3 = () => {
    setLocked(!locked)
    setOpen3(!open3)
  }


  const { state, logo, dispatch, setFooterVisible, isCategoryPage, imgsLoaded, setCategories } = useAppContext()

  const { cartList } = state.cart
  const { isAuth, canPost } = state.auth
  const { customer } = state.auth

  const screenWidth = useWindowSize()
  const screenHeight = useWindowHeight()

  const [openSearch, setOpenSearch] = React.useState(false)

  const { headerImage } = usePage().props
  const { locale } = usePage().props

  const styleSidenav = [{

    '.MuiDrawer-paper': {
      backgroundColor: 'rgba(35, 31, 32, 0.9)',
      backdropFilter: 'blur(8px)',
      overflow: 'initial',
      borderTopLeftRadius: 30,
      borderBottomLeftRadius: 30,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundBlendMode: 'color',
      width: screenWidth > 768 ? 608 : 330,
      backgroundImage: `url(${Bg})`

    },

    '.close-button': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: 'none',
      cursor: 'pointer',
      borderRadius: '8px',
      backgroundColor: 'black',
      minHeight: '50px',
      width: '50px',
      position: 'relative',
      top: '4rem',
      right: '25px',
      border: 'solid 1px white',
      transition: '0.3s ease'
    },


    '.close-button > svg': {
      transition: '0.3s ease'
    },

    '.close-button:hover': {

      backgroundColor: 'silver',
      'svg': {
        color: 'black'
      }
    }
  }]

  useEffect(() => {
    i18n.changeLanguage(locale.toUpperCase())
    AxiosService.defaults.headers.common['X-CSRF-TOKEN'] = window.document.head.querySelector('meta[name="csrf-token"]').content
  }, [])



  useEffect(() => {

    const getCategoriesData = async () => {

      const categories = await AxiosService.get(`/categories/${locale}`);
      setCategories(categories.data.categories)
    };

    getCategoriesData(); // run it, run it

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  useEffect(() => {


    const initCart = async () => {

      let cartItems = [];

      AxiosService.get('/mini-cart')
        .then((response) => {

          if (!response.data.status) {
            return
          }

          for (let i = 0; i < response.data.mini_cart.cart_items.length; i++) {

            cartItems.push(response.data.mini_cart.cart_items[i]);

          }

          if (cartItems.length) {
            dispatch(
              {
                'cart':
                {
                  type: "SET_CART",
                  payload: cartItems,
                }
              }
            )
          }

        }).catch(
          function (error) {
            console.log(error)

          }
        )



    };

    initCart(); // run it, run it

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);


  useEffect(() => {

    const checkAuth = async () => {

      if (!canPost) {
        return;
      }

      AxiosService.get('/api/is-auth')
        .then((response) => {


          if (!response.data['status']) {
            return;
          }

          if (response.data['isAuth']) {
            dispatch(
              {
                'auth': {
                  type: "SIGN_IN",
                  payload: {
                    token: '',
                    isAuth: true,
                    customer: response.data['customer_name']
                  },
                }
              }
            )

          }


        }).catch(
          function (error) {
            console.log(error)
          }
        )
    }



    checkAuth(); // run it, run it

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);


  useEffect(() => {
    if (!isCategoryPage) {
      setFooterVisible(true)
    }

  }, [])



  const getTotalPrice = () => {
    return (
      cartList.reduce(

        (accumulator, item) => accumulator + parseFloat(item.price_with_discount ? item.price_with_discount : item.price) * item.qty,
        0
      ) || 0
    )
  }


  const handleClose = () => {
    setOpenSnack(false)
  }

  //console.log('isAuth => ', isAuth)

  const handleRoute = (routeTo: string | URL) => () => {
    router.visit(routeTo);
  };

  // Afairei to teleuteo S

  const removeLastLetter = (string: string | any[]) => {
    const lastLetter = string?.slice(-1)
    if (lastLetter === 'S' || lastLetter === 's' || lastLetter === 'Σ' || lastLetter === 'σ' || lastLetter === 'ς') {
      return string.slice(0, -1)
    }
    return string
  }


  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={1} ref={ref} variant="filled" {...props} />;
  });

  const CartHandle = () => (
    <FlexBox onClick={toggleSidenav} alignItems="flex-start" position='relative'>
      <IconButton bg="transparent" color={'white'} p={0}>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
          <defs>
            <clipPath id="clip-path">
              <rect id="Path" width="30" height="30" fill="rgba(255,255,255,0)" />
            </clipPath>
          </defs>
          <g id="Mask_Group_12" data-name="Mask Group 12" clipPath="url(#clip-path)">
            <g id="Group_120" data-name="Group 120" transform="translate(3.503 3.5)">
              <path id="Path_1543" data-name="Path 1543" d="M876,294a6.145,6.145,0,0,1-4.1-1.5A5.82,5.82,0,0,1,870,288a1,1,0,0,1,2,0,4,4,0,1,0,8,0,1,1,0,0,1,2,0,5.82,5.82,0,0,1-1.9,4.5A6.145,6.145,0,0,1,876,294Z" transform="translate(-864.003 -279)" fill="#fff" />
              <path id="Path_1544" data-name="Path 1544" d="M876,282c4.842,0,7.173.663,8.6,2.448a9.934,9.934,0,0,1,1.647,3.681c.362,1.325.682,2.918,1.053,4.762q.106.53.222,1.1l0,.023c.937,5.243.478,8.332-1.486,10.015a7.937,7.937,0,0,1-3.968,1.608,50.723,50.723,0,0,1-12.152,0,7.937,7.937,0,0,1-3.968-1.609c-1.964-1.683-2.422-4.771-1.485-10.015l0-.023q.115-.567.221-1.1c.37-1.845.69-3.437,1.053-4.762a9.934,9.934,0,0,1,1.647-3.68C868.827,282.663,871.158,282,876,282Zm9.563,12.374q-.114-.564-.22-1.091c-.363-1.808-.677-3.369-1.021-4.628a8.16,8.16,0,0,0-1.279-2.958c-.817-1.019-2.327-1.7-7.042-1.7s-6.226.679-7.043,1.7a8.16,8.16,0,0,0-1.279,2.957c-.344,1.259-.658,2.82-1.021,4.628q-.105.526-.22,1.09c-1.03,5.777-.025,7.409.82,8.133a6.189,6.189,0,0,0,3,1.156,49.082,49.082,0,0,0,11.48,0,6.19,6.19,0,0,0,3-1.155C885.588,301.783,886.593,300.151,885.563,294.374Z" transform="translate(-864.003 -282)" fill="#fff" />
            </g>
          </g>
        </svg>

      </IconButton>

      <Tiny className='price-flair' >{getTotalPrice().toFixed(2) > 0 &&
        `${getTotalPrice().toFixed(2)} €`
      }
      </Tiny>
      {!!cartList.length && (
        <FlexBox
          borderRadius="300px"
          bg="error.main"
          px="5px"
          alignItems="center"
          justifyContent="center"
          ml="-0.6rem"
          mt="-9px"
          className='quantity-cart-chip'
        >
          <Tiny color="white" fontWeight="600" >
            {cartList.length}
          </Tiny>
        </FlexBox>
      )}
    </FlexBox>
  );

  const MenuHandle = () => (
    <FlexBox onClick={toggleSidenav2}>
      <IconButton variant='contained' bg="transparent" p={0} color={'white'} >
        {/* <Icon className='menuIconMenu' size="40px">menu</Icon> */}
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
          <defs>
            <clipPath id="clip-path">
              <rect id="Path" width="30" height="30" fill="rgba(255,255,255,0)" />
            </clipPath>
          </defs>
          <g id="Mask_Group_11" data-name="Mask Group 11" clipPath="url(#clip-path)">
            <g id="Group_119" data-name="Group 119" transform="translate(1.647 7.072)">
              <path id="Line_9" data-name="Line 9" d="M24.277,2.171H.429A1.513,1.513,0,0,1-1,.586,1.513,1.513,0,0,1,.429-1H24.277A1.513,1.513,0,0,1,25.706.586,1.513,1.513,0,0,1,24.277,2.171Z" transform="translate(1 1)" fill="#fff" />
              <path id="Line_10" data-name="Line 10" d="M17.133,2.171H.429A1.513,1.513,0,0,1-1,.586,1.513,1.513,0,0,1,.429-1h16.7A1.513,1.513,0,0,1,18.562.586,1.513,1.513,0,0,1,17.133,2.171Z" transform="translate(8.144 7.343)" fill="#fff" />
              <path id="Line_11" data-name="Line 11" d="M11.418,2.171H.429A1.513,1.513,0,0,1-1,.586,1.513,1.513,0,0,1,.429-1h10.99A1.513,1.513,0,0,1,12.847.586,1.513,1.513,0,0,1,11.418,2.171Z" transform="translate(13.859 13.685)" fill="#fff" />
            </g>
          </g>
        </svg>
      </IconButton>
    </FlexBox>
  )

  const UserHandle = () => (
    <FlexBox onClick={toggleSidenav3}>
      <IconButton bg="transparent" p={0} color={'white'} >
        {isAuth ?
          <svg xmlns="http://www.w3.org/2000/svg" xmlns: xlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
            <defs>
              <clipPath id="clip-path">
                <rect id="Path" width="30" height="30" fill="rgba(255,255,255,0)" />
              </clipPath>
            </defs>
            <g id="user-loggedin" clip-path="url(#clip-path)">
              <g id="Group_10521" data-name="Group 10521" transform="translate(4 3)">
                <path id="Ellipse_1" data-name="Ellipse 1" d="M6,0A6,6,0,1,1,0,6,6.007,6.007,0,0,1,6,0Z" transform="translate(17 12) rotate(180)" fill="#fff" />
                <path id="Path_1543" data-name="Path 1543" d="M516,512c2.416,0,4.177.064,5.541.2a10.861,10.861,0,0,1,3.356.753,3.189,3.189,0,0,1,1.659,1.526,7.354,7.354,0,0,1,0,5.036,3.189,3.189,0,0,1-1.658,1.526,10.861,10.861,0,0,1-3.357.754c-1.364.138-3.124.2-5.541.2s-4.177-.064-5.541-.2a10.862,10.862,0,0,1-3.356-.753,3.19,3.19,0,0,1-1.659-1.526,7.353,7.353,0,0,1,0-5.036,3.189,3.189,0,0,1,1.658-1.526,10.863,10.863,0,0,1,3.357-.754C511.823,512.064,513.584,512,516,512Z" transform="translate(-505 -498)" fill="#fff" />
              </g>
            </g>
          </svg> :
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
            <defs>
              <clipPath id="clip-path">
                <rect id="Path" width="30" height="30" fill="rgba(255,255,255,0)" />
              </clipPath>
            </defs>
            <g id="Mask_Group_13" data-name="Mask Group 13" clipPath="url(#clip-path)">
              <g id="Group_121" data-name="Group 121" transform="translate(4 3)">
                <path id="Ellipse_1" data-name="Ellipse 1" d="M6,0A6,6,0,1,1,0,6,6.007,6.007,0,0,1,6,0ZM6,10A4,4,0,1,0,2,6,4,4,0,0,0,6,10Z" transform="translate(17 12) rotate(180)" fill="#fff" />
                <path id="Path_1543" data-name="Path 1543" d="M516,512c2.416,0,4.177.064,5.541.2a10.861,10.861,0,0,1,3.356.753,3.189,3.189,0,0,1,1.659,1.526,7.354,7.354,0,0,1,0,5.036,3.189,3.189,0,0,1-1.658,1.526,10.861,10.861,0,0,1-3.357.754c-1.364.138-3.124.2-5.541.2s-4.177-.064-5.541-.2a10.862,10.862,0,0,1-3.356-.753,3.19,3.19,0,0,1-1.659-1.526,7.353,7.353,0,0,1,0-5.036,3.189,3.189,0,0,1,1.658-1.526,10.863,10.863,0,0,1,3.357-.754C511.823,512.064,513.584,512,516,512Zm0,8c2.347,0,4.044-.061,5.339-.192a9.343,9.343,0,0,0,2.73-.585,1.223,1.223,0,0,0,.689-.584,5.689,5.689,0,0,0,0-3.28,1.224,1.224,0,0,0-.69-.584,9.344,9.344,0,0,0-2.729-.584c-1.3-.131-2.992-.192-5.339-.192s-4.044.061-5.339.192a9.344,9.344,0,0,0-2.73.585,1.223,1.223,0,0,0-.689.584,5.689,5.689,0,0,0,0,3.28,1.224,1.224,0,0,0,.69.584,9.345,9.345,0,0,0,2.729.584C511.956,519.939,513.653,520,516,520Z" transform="translate(-505 -498)" fill="#fff" />
              </g>
            </g>
          </svg>}
      </IconButton>
    </FlexBox>
  )

  const handleTranslate = (lng: string) => {
    //i18n.changeLanguage(lng)
    //lng === 'EN' ? window.location.assign(`/?locale=en`) : window.location.assign(`/?locale=el`)
    
    const url = new URL(window.location.href);
    url.searchParams.set('locale', lng?.toLowerCase())
    window.location.assign(url.search);

  }

  return (
    <>
      {imgsLoaded &&

        <StyledHeader className={className} >

          <Box position='absolute' right={30} top={1}>
            <Breadcrumbs sx={{
              fontFamily: 'inherit',
              '@media only screen and (max-width : 900px)': {
                fontSize: '0.7rem'
              }
            }}
              aria-label="breadcrumb" color='white' separator="|">
              {Object.keys(lngs).map((lng) => (
                <MUILink
                  component="button"
                  underline="hover"
                  key={lng}
                  color={i18n.resolvedLanguage === lng ? "white" : 'grey'}
                  onClick={() => handleTranslate(lng)}

                >
                  {lng}
                </MUILink>
              ))}
            </Breadcrumbs>
          </Box>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openSnack}
            autoHideDuration={8000}
            sx={{ mt: screenWidth > 560 ? 15 : 9, width: '85%', maxWidth: 350, mx: 'auto' }}
          >
            <Alert onClose={handleClose} sx={{ width: '100%', backgroundColor: 'black', border: 'solid 1px white' }}>
              <FlexBox flexDirection='row' flexWrap={'wrap'} >
                <Box>{t("welcome")}&nbsp; &nbsp;</Box>
                <Box color='royalblue'>{removeLastLetter(customer)} </Box>
              </FlexBox>
            </Alert>
          </Snackbar>

          <Container
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="100%"
            maxWidth={'100%'}
            marginLeft={30}
            marginRight={30}
            style={{ fontWeight: '100' }}
          >
            {/* <FlexBox justifyContent="center" flex="1 1 0">
              <SearchBox />
            </FlexBox> */}

            {screenWidth > 768 &&
              <FlexBox flexDirection='column' color={'white'} fontSize={20} className='info-header flex-33' flexBasis={'25%'}>

                <a href='mailto:info@soma.com.gr'>
                  <FlexBox alignItems={'center'} style={{ gap: '10px' }}>
                    <svg id="envelope-Regular" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <rect id="Path" width="30" height="30" fill="rgba(255,255,255,0)" />
                      <path id="envelope-Regular_1_" d="M556,1302.25H546a4.756,4.756,0,0,0-4.75,4.75v6a4.756,4.756,0,0,0,4.75,4.75h10a4.756,4.756,0,0,0,4.75-4.75v-6A4.756,4.756,0,0,0,556,1302.25Zm-10,1.5h10a3.255,3.255,0,0,1,3,2.013l-6.2,4.134a3.245,3.245,0,0,1-3.6,0l-6.2-4.134A3.251,3.251,0,0,1,546,1303.75Zm10,12.5H546a3.254,3.254,0,0,1-3.25-3.25v-5.6l5.615,3.744a4.739,4.739,0,0,0,5.27,0l5.615-3.744v5.6A3.254,3.254,0,0,1,556,1316.25Z" transform="translate(-539 -1298)" fill="#fff" />
                    </svg>
                    <H3>info@soma.com.gr</H3>
                  </FlexBox>
                </a>

                <a href="tel:+30 25410 32442">
                  <FlexBox alignItems={'center'} style={{ gap: '10px' }}>
                    <svg id="phone-Light" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <rect id="Path" width="30" height="30" fill="rgba(255,255,255,0)" />
                      <path id="phone-Light_1_" d="M752.44,653.643a3.277,3.277,0,0,0-.075-.333,1.471,1.471,0,0,0-1.186-1.033l-4.332-.721a1.5,1.5,0,0,0-1.432.549c-.051.064-.1.13-.145.2a.543.543,0,0,1-.671.222,9.857,9.857,0,0,1-5.126-5.127.538.538,0,0,1,.218-.667c.089-.061.175-.125.26-.193a1.514,1.514,0,0,0,.541-1.411l-.67-4.255a1.489,1.489,0,0,0-1-1.191,3.591,3.591,0,0,0-.474-.119,4.035,4.035,0,0,0-3.349.93,4.283,4.283,0,0,0-1.5,3.375,15.043,15.043,0,0,0,14.63,14.631h.116a4.274,4.274,0,0,0,3.242-1.49A4.068,4.068,0,0,0,752.44,653.643Zm-1.708,2.714a3.233,3.233,0,0,1-2.574,1.142A14.041,14.041,0,0,1,734.5,643.841a3.284,3.284,0,0,1,1.149-2.589,3.057,3.057,0,0,1,1.994-.75,2.924,2.924,0,0,1,.526.047,2.483,2.483,0,0,1,.335.083.493.493,0,0,1,.327.4l.671,4.255a.514.514,0,0,1-.182.477c-.062.051-.127.1-.194.144a1.532,1.532,0,0,0-.572,1.892,10.854,10.854,0,0,0,5.645,5.646,1.539,1.539,0,0,0,1.9-.576c.033-.048.068-.1.1-.143a.5.5,0,0,1,.481-.181l4.332.721a.471.471,0,0,1,.387.316,2.12,2.12,0,0,1,.052.234A3.066,3.066,0,0,1,750.732,656.357Z" transform="translate(-731 -637)" fill="#fff" />
                    </svg>
                    <H3>+30 25410 32442</H3>
                  </FlexBox>
                </a>
                {isAuth === true
                  ? <Span className='bulk' > {t('bulk')} </Span>
                  : <Link className='bulk' href={isAuth ? '' : '/customer/login'} > {t('bulk')} </Link>
                }
              </FlexBox>
            }

            {screenWidth <= 768 &&
              <FlexBox className='info-header flex-33' flexDirection='column' >
                <FlexBox className="header-left-mobile" alignItems='center'>

                  {!isAuth
                    ? <>
                      <UserHandle />

                      <Drawer
                        anchor='right'
                        open={open3}
                        sx={styleSidenav}
                        onClose={toggleSidenav3}
                        disableScrollLock={false}
                      >
                        <Box onClick={toggleSidenav3} className='close-button'>
                          <NavigateNextIcon fontSize="large" sx={{ color: 'white' }} />
                        </Box>

                        <Login setOpen3={setOpen3} setOpenSnack={setOpenSnack} />

                        {screenHeight > 450 &&
                          <FlexBox className="sidenav-footer" alignItems="center" position={'absolute'} bottom={0} flexDirection='column'
                            mb={screenHeight > 670 ? '2rem' : '0.5rem'} style={screenHeight > 650 ? { gap: '2rem' } : { gap: '0.5rem' }}>
                            <Divider />
                            <Link href='/'>
                              {headerImage && <Image width={'50%'} margin='auto' src={headerImage} alt="logo" />}
                            </Link>

                            <FlexBox color={'white'} >
                              <a href="https://www.facebook.com/soma.com.gr" target="_blank" >
                                <Icon variant={screenWidth > 1920 ? "xlarge" : 'small'} defaultcolor="auto" mr="0.5rem" className='social-icon'>
                                  facebook
                                </Icon>
                              </a>
                              <a href="https://www.instagram.com/soma.com.gr" target="_blank" >
                                <Icon variant={screenWidth > 1920 ? "xlarge" : 'small'} defaultcolor="auto" mr="0.5rem" className='social-icon'>
                                  instagram
                                </Icon></a>
                            </FlexBox>
                          </FlexBox>
                        }
                      </Drawer>

                    </>
                    :
                    <>
                      <UserHandle />

                      <Drawer
                        anchor='right'
                        open={open3}
                        sx={styleSidenav}
                        onClose={toggleSidenav3}
                      >
                        <Box onClick={toggleSidenav3} className='close-button'>
                          <NavigateNextIcon fontSize="large" sx={{ color: 'white' }} />
                        </Box>
                        <CustomerDashboardNavigation />
                        <FlexBox alignItems="center" position={'absolute'} bottom={0} flexDirection='column' mb={'2rem'} style={{ gap: '2rem' }}>
                          <Divider />
                          <Link href='/'>
                            {headerImage && <Image width={'50%'} margin='auto' src={headerImage} alt="logo" />}
                          </Link>
                          <FlexBox color={'white'} flexDirection='row' >
                            <a href="https://www.facebook.com/soma.com.gr" target="_blank" >
                              <Icon variant={screenWidth > 1920 ? "xlarge" : 'small'} defaultcolor="auto" mr="0.5rem" className='social-icon'>
                                facebook
                              </Icon>
                            </a>
                            <a href="https://www.instagram.com/soma.com.gr" target="_blank" >
                            <Icon variant={screenWidth > 1920 ? "xlarge" : 'small'} defaultcolor="auto" mr="0.5rem" className='social-icon'>
                              instagram
                            </Icon>
                          </a>
                        </FlexBox>
                      </FlexBox>
                    </Drawer>

                </>
                  }
                <Search setOpenSearch={setOpenSearch} open={openSearch} />

              </FlexBox>
                {/* <Tiny >Χονδρική Πώληση - B2B</Tiny> */}
          </FlexBox>
            }

          <FlexBox className="logo flex-33" alignItems="center" justifyContent='center'>
            <Link href='/'>
              <Image src={headerImage} alt="logo" />
            </Link>
          </FlexBox>


          <FlexBox className="header-right flex-33" alignItems="center" justifyContent={'flex-end'}>

            {/**************         Search         ****************/}
            {screenWidth >= 768 && <Search setOpenSearch={setOpenSearch} open={openSearch} />}

            {/**************      User Log Sidenav      **************/}
            {/* {!isAuth & screenWidth >= 768
            ?
            <Sidenav
              handle={userHandle}
              position="right"
              open={open3}
              width={screenWidth > 768 ? 508 : 350}
              toggleSidenav={toggleSidenav3}
            >
              <Box onClick={toggleSidenav3} className='close-button'>
                <NavigateNextIcon fontSize="large" sx={{ color: 'white' }} />
              </Box>

              <Login setOpenSnack={setOpenSnack} />

              {screenHeight > 450 &&
                <FlexBox className="sidenav-footer" alignItems="center" position={'absolute'} bottom={0} flexDirection='column'
                  mb={screenHeight > 670 ? '2rem' : '0.5rem'} style={screenHeight > 650 ? { gap: '2rem' } : { gap: '0.5rem' }}>
                  <Divider />
                  <Link href='/'>
                    {logo ? <Image width={'50%'} margin='auto' src={logo} alt="logo" /> : null}
                  </Link>

                  <FlexBox color={'white'} >
                    <a href="https://www.facebook.com/sucunderwear" target="_blank" >
                      <Icon variant={screenWidth > 1920 ? "xlarge" : 'small'} defaultcolor="auto" mr="0.5rem" className='social-icon'>
                        facebook
                      </Icon>
                    </a>

                    <Icon variant={screenWidth > 1920 ? "xlarge" : 'small'} defaultcolor="auto" mr="0.5rem" className='social-icon'>
                      instagram
                    </Icon>
                  </FlexBox>
                </FlexBox>
              }
            </Sidenav>
            : screenWidth >= 768 ?

              <Sidenav width={screenWidth > 768 ? 508 : 350} position="right"
                handle={
                  <FlexBox className="dropdown-handler" alignItems="center">
                    <IconButton bg="unset" p={0} color='white'>
                      <Icon variant='large' className='logged-in' >user_filled</Icon>
                    </IconButton>
                  </FlexBox>
                }
                open={openLoginMenu}
                toggleSidenav={toggleLoginSidenav}
              >
                <Box onClick={toggleLoginSidenav} className='close-button'>
                  <NavigateNextIcon fontSize="large" sx={{ color: 'white' }} />
                </Box>
                <CustomerDashboardNavigation />
                <FlexBox alignItems="center" position={'absolute'} bottom={0} flexDirection='column' mb={'0.5rem'} style={{ gap: '0.5rem' }}>
                  <Divider />
                  <Link href='/'>
                    {logo ? <Image width={'50%'} margin='auto' src={logo} alt="logo" /> : null}
                  </Link>
                  <FlexBox color={'white'} >
                    <Icon variant={screenWidth > 1920 ? "xlarge" : 'small'} defaultcolor="auto" mr="0.5rem" className='social-icon'>
                      facebook
                    </Icon>
                    <Icon variant={screenWidth > 1920 ? "xlarge" : 'small'} defaultcolor="auto" mr="0.5rem" className='social-icon'>
                      instagram
                    </Icon>
                  </FlexBox>
                </FlexBox>
              </Sidenav>
              : null
          } */}

            {!isAuth && screenWidth >= 768
              ? <>
                <UserHandle />
                <Drawer
                  anchor='right'
                  open={open3}
                  sx={styleSidenav}
                  onClose={toggleSidenav3}
                >
                  <Box onClick={toggleSidenav3} className='close-button'>
                    <NavigateNextIcon fontSize="large" sx={{ color: 'white' }} />
                  </Box>

                  <Login setOpen3={setOpen3} setOpenSnack={setOpenSnack} />

                  {screenHeight > 450 &&
                    <FlexBox className="sidenav-footer" alignItems="center" position={'absolute'} bottom={0} flexDirection='column'
                      mb={screenHeight > 670 ? '2rem' : '0.5rem'} style={screenHeight > 650 ? { gap: '2rem' } : { gap: '0.5rem' }}>
                      <Divider />
                      <Link href='/'>
                        {headerImage && <Image width={'50%'} margin='auto' src={headerImage} alt="logo" />}
                      </Link>

                      <FlexBox color={'white'} >
                        <a href="https://www.facebook.com/soma.com.gr" target="_blank" >
                          <Icon variant={screenWidth > 1920 ? "xlarge" : 'small'} defaultcolor="auto" mr="0.5rem" className='social-icon'>
                            facebook
                          </Icon>
                        </a>
                        <a href="https://www.instagram.com/soma.com.gr" target="_blank" >
                          <Icon variant={screenWidth > 1920 ? "xlarge" : 'small'} defaultcolor="auto" mr="0.5rem" className='social-icon'>
                            instagram
                          </Icon></a>
                      </FlexBox>
                    </FlexBox>
                  }
                </Drawer>
              </>
              : screenWidth >= 768 ?
                <>
                  <UserHandle />

                  <Drawer
                    anchor='right'
                    open={open3}
                    sx={styleSidenav}
                    onClose={toggleSidenav3}
                  >
                    <Box onClick={toggleSidenav3} className='close-button'>
                      <NavigateNextIcon fontSize="large" sx={{ color: 'white' }} />
                    </Box>
                    <CustomerDashboardNavigation />
                    <FlexBox alignItems="center" position={'absolute'} bottom={0} flexDirection='column' mb={'0.5rem'} style={{ gap: '0.5rem' }}>
                      <Divider />
                      <Link href='/'>
                        {headerImage && <Image width={'50%'} margin='auto' src={headerImage} alt="logo" />}
                      </Link>
                      <FlexBox color={'white'} >
                        <a href="https://www.facebook.com/soma.com.gr" target="_blank" >
                          <Icon variant={screenWidth > 1920 ? "xlarge" : 'small'} defaultcolor="auto" mr="0.5rem" className='social-icon'>
                            facebook
                          </Icon></a>
                        <a href="https://www.instagram.com/soma.com.gr" target="_blank" >
                          <Icon variant={screenWidth > 1920 ? "xlarge" : 'small'} defaultcolor="auto" mr="0.5rem" className='social-icon'>
                            instagram
                          </Icon></a>
                      </FlexBox>
                    </FlexBox>
                  </Drawer>
                </>
                : null

            }

            {/**************       Cart Sidenav      ***************/}

            <CartHandle />
            <Drawer
              anchor='right'
              open={open}
              sx={styleSidenav}
              onClose={toggleSidenav}
            >
              <Box onClick={toggleSidenav} className='close-button'>
                <NavigateNextIcon fontSize="large" sx={{ color: 'white' }} />
              </Box>
              <MiniCart toggleSidenav={toggleSidenav} />
            </Drawer>

            {/****************     Menu Sidenav      ***************/}
            <MenuHandle />
            <Drawer
              anchor='right'
              open={open2}
              sx={styleSidenav}
              onClose={toggleSidenav2}
            >
              <Box onClick={toggleSidenav2} className='close-button'>
                <NavigateNextIcon fontSize="large" sx={{ color: 'white' }} />
              </Box>

              <SideMenu />
              {screenHeight > 630 &&
                <FlexBox alignItems="center" position={'absolute'} bottom={0} flexDirection='column' mb={'2rem'} style={{ gap: '2rem' }}>
                  <Divider />
                  <Link href='/'>
                    <Image width={'50%'} margin='auto' src={headerImage} alt="logo" />
                  </Link>
                  <FlexBox color={'white'} style={{ gap: 10 }}>
                    <a href='https://www.facebook.com/soma.com.gr' target='_blank'>
                      <Icon variant={screenWidth > 1920 ? "xlarge" : 'small'} defaultcolor="auto" mr="0.5rem">
                        facebook
                      </Icon></a>
                    <a href='https://www.instagram.com/soma.com.gr/' target='_blank'>
                      <Icon variant={screenWidth > 1920 ? "xlarge" : 'small'} defaultcolor="auto" mr="0.5rem">
                        instagram
                      </Icon></a>
                  </FlexBox>
                </FlexBox>
              }

            </Drawer>
          </FlexBox>
        </Container>
        </StyledHeader >}
    </>
  )
}


export default Header;


